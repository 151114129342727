import React from 'react'
import { useSession } from 'next-auth/react'
import { useState, useEffect } from 'react'
import { Alert } from '@material-tailwind/react'
import { getAccessToken } from './withsession'
import { LinkButton } from './buttons/LinkButton'

export const PaywallPayButton = () => {
    
    const { data: session } = useSession({ required: true })
    
    const [checkoutUrl, setCheckoutUrl] = useState<string|null>(null)
    const [error, setError] = useState<string|null>(null)
    
    //the checkout urls for users are dynamically generated and have expiration dates. we need to request one.
    //todo: we can actually cache these, and check if they have already expired, instead of making a new call every time
    useEffect(() => {
        async function getCheckoutUrl() {
            try {
                if (!session) {
                    return //we're gonna need to wait for the session, first
                }
                
                const token = getAccessToken(session)
                if (null === token) {
                    throw new Error('invalid session')
                }
                
                const response = await fetch('/backend/checkout/get-checkout-url', {
                    method: 'GET',
                    headers: {
                        'authorization': 'Bearer ' + token
                    }
                })
                
                if (response.status !== 200) throw new Error()
                
                const checkoutData = await response.json()
                const checkoutUrl: string|null = checkoutData && checkoutData.url ? checkoutData.url : null
                if (!checkoutUrl) throw new Error()
                
                setError(null)
                setCheckoutUrl(checkoutUrl)
                
            } catch (e) {
                console.error(e)
                setError('Cannot generate a checkout URL. Please try again later.')
                setCheckoutUrl(null)
            }
        }
        
        if (!checkoutUrl) {
            getCheckoutUrl()
        }
    }, [session])
    
    return (
        <>
            { !checkoutUrl && <span className="mt-8 text-sm">loading...</span> }
            { checkoutUrl && <LinkButton className="bg-wing-gradient mt-8 w-full text-white" href={checkoutUrl}>Zur Zahlung</LinkButton> }
            { error && <Alert className="mt-8">
                {error}
            </Alert> }
        </>
    )
}
