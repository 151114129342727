import * as React from 'react'
import classNames from 'classnames'

type IconProps = {
    className: string
};

export const CheckmarkIcon = ({ className, ...props }: IconProps) => {
    const classes = classNames('', className)
    return (
        <svg
            className={classes}
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 18 13"
            {...props}
        >
            <path
                fillRule="nonzero"
                d="M12.915 20.871l-6.18 6.18 1.414 1.414 4.766-4.766 10.128 10.129 1.414-1.414-11.542-11.543z"
                transform="matrix(1 0 0 -1 -6.735 33.828)"
            ></path>
        </svg>
    )
}
