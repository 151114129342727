//material-tailwind has this ridiculous theming system,
//where each component has to be given dozens of lines for setting the custom colors...
//@see https://www.material-tailwind.com/docs/react/theming#theme-provider

export const wingmenMaterialTheme = {
    textarea: {
        input: {
            defaultProps: {
                variant: 'outlined',
                color: 'teal',
                className: 'autofill:bg-black border-wing-gray-200 text-wing-gray-200 rounded-br-md rounded-bl-md rounded-tr-md rounded-tl-md autofill:shadow-[inset_0_0_0px_1000px_rgb(0,0,0)] autofill:text-wing-teal',
                labelProps: {
                    className: 'text-wing-gray-200 text-wing-gray-200 autofill:bg-black'
                },

            }
        },
        button: {
            defaultProps: {
                className: 'text-lg rounded-br-md rounded-bl-md rounded-tr-md rounded-tl-md'
            },
        },
        defaultProps: {
            color: 'neutral' //use this custom coloring by default
        },
        valid: {
            colors: ['neutral']
        },
        styles: {
            base: {
                label: {
                    color: 'text-wing-gray-200'
                },
                textarea: {
                    color: 'text-white'
                }
            },
            variants: {
                outlined: {
                    colors: {
                        textarea: {
                            'neutral': {
                                'borderColor': 'placeholder-shown:border-white border-white',
                                'borderColorFocused': 'focus:border-gray-100'
                            }
                        },
                        label: {
                            'neutral': {
                                'color': 'text-white peer-focus:text-white',
                                'before': 'before:border-gray-100 peer-focus:before:border-gray-100',
                                'after': 'border-gray-100 after:border-gray-100 peer-focus:after:border-gray-100'
                            }
                        }
                    }
                }
            }
        }
    },
    
    radio: {
        defaultProps: {
            color: 'neutral'
        },
        valid: {
            colors: ['neutral']
        },
        styles: {
            base: {
                label: {
                    color: 'text-white'
                }
            },
            colors: {
                'neutral': {
                    color: 'text-white',
                    border: 'checked:border-blue-gray-200',
                    before: 'checked:before:bg-blue-gray-200'
                }
            }
        }
    },
    
    checkbox: {
        defaultProps: {
            'color': 'neutral'
        },
        valid: {
            colors: ['neutral']
        },
        styles: {
            base: {
                label: {
                    color: 'text-white'
                }
            },
            colors: {
                'neutral': {
                    background: 'checked:bg-wing-gradient',
                    border: 'checked:border-deep-orange-500',
                    before: 'checked:before:bg-deep-orange-500'
                }
            }
        }
    }
}
