import React from 'react'
import { Sidebar } from './layout/Sidebar'

export const Layout = ({ children }: {children: React.ReactNode}) => {
    return (
        <div className="wing-page-container">
            <Sidebar>
                {children}
            </Sidebar>
        </div>
    )
}
