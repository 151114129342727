import * as React from 'react'
import classNames from 'classnames'

type IconProps = {
    className: string
};

export const UnlockedIcon = ({ className, ...props }: IconProps) => {
    const classes = classNames('', className)
    return (
        <svg
            className={classes}
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 13 17"
            {...props}
        >
            <path
                fillRule="nonzero"
                d="M10.564 72.133v1.669a4.405 4.405 0 01-4.374 4.372 4.531 4.531 0 01-2.826-1.086l1.046-1.046a2.746 2.746 0 001.739.63h.042a2.884 2.884 0 002.872-2.873V72.13H0v-9.956h12.377v9.959h-1.813zm.309-8.457H1.504v6.955h9.369v-6.955z"
                transform="matrix(1 0 0 -1 0 78.174)"
            ></path>
        </svg>
    )
}
