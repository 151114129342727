import { createContext, useContext, useEffect, useState, ReactNode } from 'react'
import { useSession } from 'next-auth/react'
import { getAccessToken } from '../components/withsession'
import { userProgress, CourseStatus } from '../services/userprogress'

export type SidebarMenuData = {
    courses: { id: string, title: string}[]
}

export interface SidebarMenuDataValue {
    sidebarMenuData: SidebarMenuData
    refreshCoursesState: () => void
    userCoursesStatus: Record<string, CourseStatus>
}

const SidebarMenuDataContext = createContext<SidebarMenuDataValue | null>(null)

type ProviderProps = Readonly<{
    sidebarMenuData: SidebarMenuData
    children: ReactNode
}>

export function SidebarMenuDataProvider({ sidebarMenuData, children }: ProviderProps) {
    const session = useSession()
    const [ userCoursesStatus, setUserCoursesStatus ] = useState<{ [key: string]: CourseStatus }>({})
    
    const refreshCoursesState = () => {        
        userProgress.getCoursesStatus(session.data)
            .then((results: Record<string, CourseStatus>) => {
                setUserCoursesStatus(results)
            })
    }
    
    useEffect(() => {
        if (!session) return
        
        refreshCoursesState()
    }, [ session, sidebarMenuData ])
    
    return (
        <SidebarMenuDataContext.Provider value={{ sidebarMenuData, userCoursesStatus, refreshCoursesState }}>{children}</SidebarMenuDataContext.Provider>
    )
}

export function useSidebarMenuData(): SidebarMenuDataValue {
    const context = useContext(SidebarMenuDataContext)
    if (context === null) {
        throw new Error('useSidebarMenu must be used within a SidebarMenuContext')
    }
    return context
}
