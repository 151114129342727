import * as React from 'react'
import classNames from 'classnames'

type IconProps = {
    className: string
};

export const DisclaimerIcon = ({ className, ...props }: IconProps) => {
    const classes = classNames('', className)

    return (
        <svg
            className={classes}
            xmlns="http://www.w3.org/2000/svg"
            width="30.658"
            height="26.847"
            viewBox="0 0 30.658 26.847"
            {...props}
        >
            <g>
                <path d="M14.255 19.702h1.893v1.884h-1.893z"></path>
                <path d="M15.329 1.506a.486.486 0 00-.433.249L1.577 24.587a.5.5 0 00.433.755h26.638a.5.5 0 00.433-.755L15.763 1.754a.486.486 0 00-.433-.249m0-1.505a1.989 1.989 0 011.734 1l13.318 22.831a2.007 2.007 0 01-1.734 3.018H2.01a2.007 2.007 0 01-1.734-3.018L13.595.996A1.989 1.989 0 0115.329 0z"></path>
                <path d="M14.255 10.094h1.893v7.889h-1.893z"></path>
            </g>
        </svg>
    )
}