import { useSession, SessionContextValue } from 'next-auth/react'
import { Session } from 'next-auth'

//this helper allows me to wrap class components with a user session, if needed
export interface SessionAwareProps<R extends boolean> {
    session: SessionContextValue<R> | {
        readonly data: null
        readonly status: 'loading'
    }
}

export const getAccessToken = (data: Session | null): string|null => {
    if (!data) return null
    if (!data.user || !data.user.isConfirmed) return null
    const tokenData = data.user.accessToken ?? null
    return tokenData ? (typeof tokenData === 'string' ? tokenData : tokenData.token) : null
}

export const withSession = (Component: any) => (props: any) => {
    const session = useSession()

    // if the component has a render property, we are good
    if (Component.prototype.render) {
        return <Component session={session} {...props} />
    }

    // if the passed component is a function component, there is no need for this wrapper
    throw new Error(
        [
            'You passed a function component, `withSession` is not needed.',
            'You can `useSession` directly in your component.',
        ].join('\n')
    )
}
