import React from 'react'
import Link from 'next/link'
import classNames from 'classnames'
import { ArrowIcon } from '../icons/ArrowIcon'

interface ButtonProperties extends React.HtmlHTMLAttributes<HTMLAnchorElement>{
    href: string
    children: React.ReactNode
    className?: string
    target?: string
    rightVariant?: boolean
    disabled?: boolean
}

export const LinkButton = ({ href, children, className, rightVariant, disabled, ...props }: ButtonProperties) => {
    disabled = typeof disabled === 'boolean' ? disabled : false
    const disabledClasses = disabled ? 'bg-transparent border-solid border-2 opacity-25 pointer-events-none' : ''
    const buttonClasses = classNames('inline-block whitespace-nowrap px-3 py-2 rounded bg-wing-gray-600 font-bold shadow-none normal-case text-base hover:brightness-125 hover:shadow-none', className, disabledClasses)

    rightVariant = typeof rightVariant === 'boolean' ? rightVariant : true


    let content
    if (rightVariant) {
        content = <>
            <span className="text-sm">{children}</span>
            <ArrowIcon className="strike-current fill-current w-6 h-6 ml-4 inline float-right"/>
        </>
    } else {
        content = <>
            <ArrowIcon className="strike-current fill-current w-6 h-6 mr-4 inline float-left rotate-180"/>
            <span className="text-sm">{children}</span>
        </>
    }

    return (
        <Link 
            href={href}
            className={buttonClasses}
            {...props}
        >
            {content}
        </Link>
    )
}
