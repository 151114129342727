import React from 'react'
import { Alert, Typography } from '@material-tailwind/react'
import { LogoWingmenWhite } from '../icons/LogoWingmenWhite'

interface SignUpInLayoutProps {
    children: React.ReactNode
    title: string
    alerts?: string
}

export default function AuthLayout(props: SignUpInLayoutProps) {
    return (
        <section className="grid md:grid-cols-8 md:grid-rows-6 items-center lg:min-h-[900px] h-screen w-full">

            {props.alerts && (

                <Alert className="col-span-6  md:col-start-2">
                    {props.alerts}
                </Alert>
            )}
            <div className="w-full h-full grid md:grid-cols-5 lg:grid-rows-1 col-span-6 md:col-start-2 md:row-start-2 row-span-4">
                <div className="h-full text-wing-gray-200 p-20 py-20 text-center my-auto col-span-5 lg:col-span-3 row-span-4 bg-wing-gray-900 lg:rounded-bl-xl lg:rounded-tl-xl">

                    <div className="flex-grow items-center pb-2 hidden lg:flex">
                        <LogoWingmenWhite className="w-7 h-7 left-0 inline" />
                    </div>
                    <div className="pb-2 lg:hidden">
                        <Typography color="white" className="mb-6 italic font-semibold lg:text-left text-lg text-center">
                            crypto<LogoWingmenWhite className="h-4 w-4 inline" />wingmen
                        </Typography>
                    </div>

                    <Typography color="white" className="mb-12 italic font-bold lg:text-left text-xl text-center">
                        {props.title}
                    </Typography>
                    {props.children}
                </div>
                <img
                    src="/img/login-image.png"
                    alt="background image"
                    className="h-full col-span-2 object-cover hidden lg:block rounded-br-xl rounded-tr-xl shrink"
                />
            </div>
        </section>
    )
}