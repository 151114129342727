import * as React from 'react'
import classNames from 'classnames'

type IconProps = {
    className?: string
    prefix?: string
};

export const LogoWingmen = ({ className, prefix, ...props }: IconProps) => {
    const classes = classNames('', className)
  
    const id = (prefix ?? '-') + '_Linear1'
  
    return (
        <svg
            className={classes}
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 70 48"
            {...props}
        >
            <path
                fill={'url(#' + id + ')'}
                fillRule="nonzero"
                d="M69.946 47.941V48H0v-3.452c0-7.515 4.941-13.974 11.928-16.653.868-6.377 5.563-11.647 11.846-13.693C25.081 6.172 32.473 0 41.371 0h12.508v6.905H41.371c-4.666 0-8.652 2.661-10.205 6.383h22.502v6.899H29.583c-4.666 0-8.657 2.661-10.211 6.383h34.806v6.899H19.132c-5.416 0-10.023 3.201-11.629 7.62H69.94"
                transform="matrix(1 0 0 -1 0 48)"
            ></path>
            <defs>
                <linearGradient
                    id={id}
                    x1="0"
                    x2="1"
                    y1="0"
                    y2="0"
                    gradientTransform="scale(-67.4153 67.4153) rotate(-61.398 .408 1.034)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#00F4A3"></stop>
                    <stop offset="0.31" stopColor="#2E9594"></stop>
                    <stop offset="0.67" stopColor="#9170E4"></stop>
                    <stop offset="1" stopColor="#EC00FF"></stop>
                </linearGradient>
            </defs>
        </svg>
    )
}