import { useRouter } from 'next/router'

export interface PageData {
    name: string
    href: string
}

const pages: Record<string, PageData> = {
    '/': { name: 'Dashboard', href: '/' },
    '/course': { name: 'Kurse', href: '/course' },
    '/community': { name: 'Community', href: '/community' },
    '/fortschritt': { name: 'Fortschritt', href: '/fortschritt' },
    '/wingmen': { name: 'Wingmen', href: '/wingmen' },
    '/library': { name: 'Bibliothek', href: '/library' },
    '/course/landing/[id]': { name: 'Kurse', href: '/course/landing/[id]' },
    '/course/lesson/[id]/overview': { name: 'Kurse', href: '/course/lesson/[id]/overview' },
    '/impressum': { name: 'Impressum', href: '/impressum' },
    '/datenschutz': { name: 'Datenschutzerklärung', href: '/datenschutz' },
    '/agb': { name: 'Allgemeine Geschäftsbedingungen', href: '/agb' },
}

const getCurrentPageData = () => {
    const router = useRouter()

    return pages[router.pathname] ?? null
}

export const getCurrentPageTitle = () => {
    const currentPage: PageData = getCurrentPageData()

    return (currentPage && currentPage.name) ?? null
}