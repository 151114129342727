import { LogoWingmen } from '../icons/LogoWingmen'
import { QuestionIcon } from '../icons/QuestionIcon'
import { LibraryIcon } from '../icons/LibraryIcon'
import { MobileNavigation } from './MobileNavigation'
import { UserMenu } from './UserMenu'
import { SidebarNavigation } from './SidebarNavigation'
import { useSession } from 'next-auth/react'
import { ReactNode } from 'react'
import { getCurrentPageTitle } from '../../services/page'
import Link from 'next/link'

export const Sidebar = ({ children }: {children: ReactNode}) => {
    const { data: session } = useSession({ required: true })

    const currentPageTitle = getCurrentPageTitle()

    if (!session || !session.user) {
        return <></>
    }

    return (
        <>
            {/* Static sidebar for desktop */}
            <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex flex-grow flex-col overflow-y-auto bg-black pt-4">
                    <div className="flex flex-shrink-0 items-center pl-4">
                        <LogoWingmen className="w-12 h-12 ml-2 inline" />
                    </div>
                    <div className="mt-5 flex flex-1 flex-col">
                        <nav className="flex-1 space-y-1 px-4 pb-4">
                            <SidebarNavigation />
                        </nav>
                    </div>
                    <span className="flex-end text-sm pb-6 pl-6 text-wing-gray-200">(c) 2023 Crypto Wingmen</span>
                </div>
            </div>
            
            {/* main column with the page content: */}
            <div className="flex flex-1 flex-col lg:pl-64 min-h-screen bg-wing-bg">
                {/* desktop header */}
                <div className="sticky top-0 z-10 flex h-0 lg:h-16 flex-shrink-0 bg-wing-bg">
                    <span className="hidden lg:block text-xl font-semibold flex-start pt-6 pl-8">{currentPageTitle}</span>
                    
                    <div className="flex flex-1 justify-between px-4">
                        <div className="flex flex-1">
                        </div>

                        <div className="ml-4 flex items-center gap-4 md:ml-6">
                            <Link
                                href="/library"
                                className="invisible lg:visible rounded-full p-1 text-white stroke-current fill-current hover:text-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-200 focus:ring-offset-1"
                            >
                                <span className="sr-only">Library</span>
                                <LibraryIcon className="h-5 w-5" aria-hidden="true" />
                            </Link>
                            <Link
                                href="https://faq.cryptowingmen.com"
                                className="invisible lg:visible rounded-full p-1 text-white stroke-current fill-current hover:text-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-200 focus:ring-offset-1"
                            >
                                <span className="sr-only">View notifications</span>
                                <QuestionIcon className="h-5 w-5" aria-hidden="true" />
                            </Link>

                            {/* Profile dropdown */}
                            <UserMenu className="relative ml-3 mt-1"></UserMenu>
                        </div>
                    </div>
                </div>

                <main>
                    {/* mobile view needs a header logo that doesn't belong to the actual header of the page */}
                    <LogoWingmen prefix="mobile-header" className="w-10 h-10 ml-2 mt-2 lg:hidden" />
                    
                    {children}
                </main>
            </div>
            
            {/* static bottom-navigation for mobile */}
            <MobileNavigation />
        </>
    )
}
