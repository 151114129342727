import * as React from 'react'
import classNames from 'classnames'

type IconProps = {
    className: string
};

export const ArrowIcon = ({ className, ...props }: IconProps) => {
    const classes = classNames('', className)
    return (
        <svg
            className={classes}
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 33 16"
            {...props}
        >
            <path
                fillRule="nonzero"
                d="M20.65 15.799l-1.391-1.391 5.523-5.525H-4V6.915h28.782l-.121-.121-5.402-5.403L20.65 0l7.9 7.9-7.9 7.899z"
                transform="matrix(1 0 0 -1 4 15.8)"
            ></path>
        </svg>
    )
}