import * as React from 'react'
import classNames from 'classnames'

type IconProps = {
    className: string
};

export const DropdownIcon = ({ className, ...props }: IconProps) => {
    const classes = classNames('', className)
    return (
        <svg
            className={classes}
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 14 9"
            {...props}
        >
            <path
                fillRule="nonzero"
                d="M7.599 0L.892 6.707l1.414 1.414 5.293-5.293 5.293 5.293 1.414-1.414L7.599 0z"
                transform="matrix(1 0 0 -1 -.892 8.121)"
            ></path>
        </svg>
    )
}
