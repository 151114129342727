import { useRouter } from 'next/router'
import { useImmer } from 'use-immer'
import { userProgress, CourseStatus } from '../../services/userprogress'
//import { DashboardIcon } from '../icons/DashboardIcon'
import { CoursesIcon } from '../icons/CoursesIcon'
import { CommunityIcon } from '../icons/CommunityIcon'
import { DropdownIcon } from '../icons/DropdownIcon'
import Link from 'next/link'
import { Disclosure } from '@headlessui/react'
import classNames from 'classnames'
import React, { ReactNode } from 'react'
import WingmenIcon from '../icons/WingmenIcon'
import { useSidebarMenuData, SidebarMenuDataValue } from '../../contexts/sidebarMenuData'

interface NavigationItemProps {
    className?: string
    children?: ReactNode
    name: string
    href: string
    icon?: string
}

//regular navigation link:
const NavigationItem = ({ name, children, href, className }: NavigationItemProps) => {
    const router = useRouter()
    const isCurrent = href === router.pathname

    return (
        <Link
            key={name}
            href={href}
            className={classNames(
                isCurrent ? 'bg-wing-bg' : '',
                'group flex items-center pl-4 pr-2 py-2 text-sm font-medium rounded-md hover:bg-wing-gray-400', className
            )}
        >
            {children}
            <span>{name}</span>
        </Link>
    )
}

export interface CourseNavigationData {
    id: string
    title: string
    status: CourseStatus
}

//dropdown navigation section link:
const CourseNavigationItem = ({ course }: { course: CourseNavigationData }) => {
    const router = useRouter()
    const { id, title, status } = course

    //we need a different icon, depending on the status of the course
    const ButtonIcon: React.ElementType = userProgress.getIconForCourseStatus(status)


    if (status === CourseStatus.Unknown) {
        return (
            <span className="group flex items-center pl-4 pr-2 py-2 text-sm font-medium text-wing-gray-200">
                <span className="pl-7 flex-grow">{title}</span>
                <ButtonIcon className="fill-wing-gray-200 h-4 w-4 mr-1"/>
            </span>)
    }

    const isCurrentPage = router.query.id && router.query.id == id

    if (status === CourseStatus.Locked) {
        return (
            <span className="group flex items-center pl-4 pr-2 py-2 text-sm font-medium text-wing-gray-200">
                <span className="pl-7 flex-grow">{title}</span>
                <ButtonIcon className="fill-wing-gray-200 h-4 w-4 mr-1"/>
            </span>)
    }

    return (
        <Link
            href={'/course/landing/' + id}
            className={classNames(
                isCurrentPage ? 'bg-wing-bg' : '',
                'group flex items-center pl-4 pr-2 py-2 text-sm font-medium rounded-md hover:bg-wing-gray-400', ''
            )}
        >
            <span className="pl-7 flex-grow">{title}</span>
            <ButtonIcon className={(isCurrentPage ? 'fill-wing-green' : 'fill-current') + ' w-4 h-4 mr-1'}/>
        </Link>
    )
}


export const SidebarNavigation = () => {
    const router = useRouter()
    const isCourseContentActive = router.pathname.indexOf('course/') !== -1
    
    const sidebarDataValue: SidebarMenuDataValue = useSidebarMenuData()
    const { courses: availableCourses } = sidebarDataValue.sidebarMenuData || {}
    if (!availableCourses) {
        return null
    }

    const [courses, updateCourseProgress] = useImmer<Record<string, CourseNavigationData>>(() => {
        const state: Record<string, CourseNavigationData> = {}
        for (const { id, title } of availableCourses) {
            state[id] = {
                id,
                title,
                status: CourseStatus.Unknown
            }
        }

        return state
    })
    
    //update the courses menu when the user's progress data becomes available in the parent context
    const { userCoursesStatus } = sidebarDataValue
    React.useEffect((): void => {
        
        updateCourseProgress(draft => {
            for (const [id, status] of Object.entries(userCoursesStatus)) {
                draft[id].status = status
            }
        })
    }, [userCoursesStatus])

    return (
        <>
            {/* the dashboard is an empty page for now, so it's been hidden:
            <NavigationItem name="Dashboard" href="/">
                <DashboardIcon className="fill-white mr-3 h-4 w-4 flex-shrink-0 text-slate-300"
                    aria-hidden="true"/>
            </NavigationItem>
            */}

            {/* the courses data */}
            <Disclosure defaultOpen={isCourseContentActive}>
                <Disclosure.Button
                    className="group flex w-full items-center pl-4 pr-2 py-2 text-sm font-medium rounded-md hover:bg-wing-gray-400"
                >
                    <CoursesIcon className="fill-white mr-3 h-4 w-4 text-slate-300"
                        aria-hidden="true"/>
                    <span className="flex-grow text-left">Kurse</span>
                    <DropdownIcon className="fill-white mr-1 h-4 w-4 text-slate-300"
                        aria-hidden="true"/>
                </Disclosure.Button>

                <Disclosure.Panel>
                    {Object.values(courses).map((course: CourseNavigationData) => (
                        <CourseNavigationItem course={course} key={course.id}/>
                    ))}
                </Disclosure.Panel>
            </Disclosure>

            <NavigationItem name="Community" href="/community">
                <CommunityIcon className="fill-white mr-3 h-4 w-4 flex-shrink-0 text-slate-300"
                    aria-hidden="true"/>
            </NavigationItem>
            <NavigationItem name="Wingmen" href="/wingmen">
                <WingmenIcon className="fill-white mr-3 h-4 w-4 flex-shrink-0 text-slate-300"
                    aria-hidden="true"/>
            </NavigationItem>
        </>
    )
}


