import * as React from 'react'
import classNames from 'classnames'

type IconProps = {
    className: string
};

export const LockedIcon = ({ className, ...props }: IconProps) => {
    const classes = classNames('', className)
    return (
        <svg
            className={classes}
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 13 17"
            {...props}
        >
            <path
                fillRule="nonzero"
                d="M10.996 106.519v1.696a4.487 4.487 0 01-4.458 4.458h-.001a4.486 4.486 0 01-4.457-4.458v-1.696H.234V96.373h12.608v10.146h-1.846zm-7.385 0v1.695a2.939 2.939 0 002.927 2.928 2.939 2.939 0 002.927-2.927v-1.696H3.611zm-1.846-1.531h9.546v-7.085H1.765v7.085z"
                transform="matrix(1 0 0 -1 -.234 112.673)"
            ></path>
        </svg>
    )
}
