import React, { useEffect } from 'react'
import { useSession } from 'next-auth/react'
import { Popover } from '@headlessui/react'
import Link from 'next/link'
import { useSidebarMenuData, SidebarMenuDataValue } from '../../contexts/sidebarMenuData'
import { CourseNavigationData } from '../layout/SidebarNavigation'
import { CourseStatus, userProgress } from '../../services/userprogress'
import { useImmer } from 'use-immer'
import { useRouter } from 'next/router'
import { useUserNavigation } from '../../contexts/userNavigation'

interface CourseMenuProps {
    className?: string
}

interface NavigationLinkProps {
    course: { id: string, title: string, status?: CourseStatus }
}

const NavigationLink = ({ course }: NavigationLinkProps) => {
    const router = useRouter()
    const { userIsOpeningMenu } = useUserNavigation()
    
    if (!course) {
        return null
    }
    

    const handleLinkClicked = () => {
        userIsOpeningMenu('courses')
    }
    
    const isCurrentPage = router.query.id && router.query.id == course.id
    const Icon: React.ElementType = userProgress.getIconForCourseStatus(course.status || CourseStatus.Unknown)
    
    if (course.status === CourseStatus.Locked || course.status === CourseStatus.Unknown || course.status === CourseStatus.AwaitingCourse) {
        return (
            <span className="bg-wing-bg m-2 px-10 py-6 text-sm font-bold text-wing-gray-200 flex justify-between">
                <span>{ course.title }</span>
                <Icon className="w-6 h-6 fill-wing-gray-200"/>
            </span>
        )
    }
    
    return (
        <Link id={ 'MobileNavigationCourseButton-' + course.title } href={'/course/landing/' + course.id} 
            onClick={handleLinkClicked}
            className={'bg-wing-bg m-2 px-10 py-6 text-sm font-bold flex justify-between' + (isCurrentPage ? ' font-black' : '')}>
            <span>{ course.title }</span>
            <Icon className={(isCurrentPage ? 'fill-wing-green' : 'fill-current') + ' w-6 h-6'} />
        </Link>
    )
}

export const CourseMenu = ({ className }: CourseMenuProps) => {
    const { data: session } = useSession({ required: true })
    
    //we can toggle this menu from a context
    const { currentOpenMenu } = useUserNavigation()
    const isOpen = currentOpenMenu === 'courses'

    const sideMenuDataValue: SidebarMenuDataValue = useSidebarMenuData()
    const { courses: availableCourses } = sideMenuDataValue.sidebarMenuData
    const userCoursesStatus: Record<string, CourseStatus> = sideMenuDataValue.userCoursesStatus
    
    const [courses, updateCourses] = useImmer<Record<string, CourseNavigationData>>(() => {
        const state: Record<string, CourseNavigationData> = {}
        for (const { id, title } of availableCourses) {
            state[id] = {
                id,
                title,
                status: CourseStatus.Unknown
            }
        }

        return state
    })

    if (!session || !session.user) {
        return <></>
    }

    //be sure to toggle the menu buttons on/off depending on user's progress in the course (which of the buttons are available to him)
    useEffect(() => {
        updateCourses(draft => {
            for (const [id, status] of Object.entries(userCoursesStatus)) {
                draft[id].status = status
            }
        })
    }, [userCoursesStatus])
    
    return (
        <Popover className={className}>

            {isOpen &&
                <Popover.Panel
                    static
                    className="w-full fixed bottom-0 mb-16 right-0 z-10 bg-black p-2">
                    <div className="">
                        {Object.values(courses).map((course) => (
                            <NavigationLink key={course.id} course={course} />
                        ))}
                    </div>
                </Popover.Panel>
            }
        </Popover>
    )
}