import * as React from 'react'
import classNames from 'classnames'

type IconProps = {
    className?: string
};

export const CommunityIcon = ({ className, ...props }: IconProps) => {
    const classes = classNames('', className)
    return (
        <svg
            className={classes}
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 22"
            {...props}
        >
            <path
                fillRule="nonzero"
                d="M37.451 22.613V8.898h3.429V4.532l1.393 1.125 4.05 3.241H54.6v13.715H37.451zm1.714-1.715H52.88V10.613h-7.152l-.242-.187-2.892-2.305v2.492h-3.429v10.285zm17.142-1.714v-1.715h3.429V7.184h-3.429V4.692l-3.133 2.492h-6.423l-2.148-1.715h7.981l5.438-4.366v4.366h3.429v13.715h-5.144z"
                transform="matrix(1 0 0 -1 -37.451 22.613)"
            ></path>
        </svg>
    )
}