import { WeekUnitType } from '../components/contentful/content-type/course'
import { Entry } from 'contentful'
import { Session } from 'next-auth'
import { getAccessToken } from '../components/withsession'
import { LockedIcon } from '../components/icons/LockedIcon'
import { UnlockedIcon } from '../components/icons/UnlockedIcon'
import { CheckmarkIcon } from '../components/icons/CheckmarkIcon'

const apiHost = process.env.API_URL

export enum CourseStatus {
    Unknown = 'unknown',
    AwaitingCourse = 'awaiting_course', //when the user applies, but his cohort is not yet ready
    Succeeded = 'succeeded',
    Completed = 'completed',
    Open = 'open',
    Locked = 'locked',
    Started = 'started',
    Failed = 'failed',
}

export enum LessonStatus {
    Unknown = 0,
    Passed = 1,
    Failed = 2,
}

export interface CourseProgress {
    courseId?: string
    status: CourseStatus
    currentCourseWeek?: number
    courseWeekUnits?: { [key: string]: { [key: string]: CourseStatus } } | null
    lessonResults?: { [key: string]: ChallengeResult } | null
    courseWeekStatus?: { [key: string]: CourseStatus } | null
}

export enum TaskSubmissionStatus {
    OpenNotSent = 0,
    SentNow = 1,
    AlreadyCompleted = 2, //instead of the "thank you" page, we may show the "submissions already sent" view here
}

export interface ChallengeResult {
    result: ChallengeStatus
}

export enum ChallengeStatus{
    Unknown = 'unknown',
    Open = 'open',
    Locked = 'locked',
    Submitted = 'submitted',
    Approved = 'approved',
    Rejected = 'rejected',
    Missed = 'missed'
}

export interface LessonResult {
    lessonId: string
    result: LessonStatus
}

export interface UserProgressApi {
    getCourseStatus(courseId: string, token: Session | null): Promise<CourseProgress>
    getIconForCourseStatus(status: CourseStatus): React.ElementType
    getStatusForWeekId(weekId: string, courseProgress: CourseProgress): CourseStatus
}

export interface CoursesStatusType {
    contentfulCourseId: string
    createdAt?: string
    id?: string
    status: CourseStatus
    updatedAt?: string
}

class UserProgress implements UserProgressApi {

    async getCoursesStatus(session: Session | null): Promise<Record<string, CourseStatus>> {
        const token = getAccessToken(session)
        if (token === null) {
            return Promise.resolve({})
        }
        
        const result = await fetch('/backend/courses/status', {
            headers: {
                'authorization': 'Bearer ' + token
            }
        })
        
        if (result.status !== 200) {
            console.error(result.status)
            return Promise.resolve({})
        }
        
        const coursesStatus: Record<string, CourseStatus> = {}
        const receivedData: CoursesStatusType[] = await result.json()
        for (const courseData of receivedData) {
            coursesStatus[courseData.contentfulCourseId] = courseData.status
        }
        
        return Promise.resolve(coursesStatus)
    }
    
    getIconForCourseStatus(status: CourseStatus): React.ElementType {
        let ButtonIcon: React.ElementType
        switch (status) {
            case CourseStatus.Open:
            case CourseStatus.AwaitingCourse:
            case CourseStatus.Started:
            case CourseStatus.Failed:
                ButtonIcon = UnlockedIcon
                break
            case CourseStatus.Succeeded:
                ButtonIcon = CheckmarkIcon
                break
            case CourseStatus.Locked:
                ButtonIcon = LockedIcon
                break

            default:
                ButtonIcon = LockedIcon
        }
        
        return ButtonIcon
    }

    async getCourseStatus(courseId: string, session: Session | null): Promise<CourseProgress> {
        const token = getAccessToken(session)
        if (token === null) {
            return Promise.resolve({
                status: CourseStatus.Unknown
            })
        }
        
        const result = await fetch(apiHost + '/course/' + courseId + '/status', {
            headers: {
                'authorization': 'Bearer ' + token
            }
        })
        
        if (result.status !== 200) {
            return Promise.resolve({
                status: CourseStatus.Unknown
            })
        }

        return Promise.resolve(result.json())
    }

    getLessonStatusFromCourseProgress(lessonId: string, studentProgress?: CourseProgress): CourseStatus
    {
        if (!studentProgress) {
            return CourseStatus.Unknown
        }
        if ([CourseStatus.Open, CourseStatus.AwaitingCourse, CourseStatus.Locked, CourseStatus.Unknown].includes(studentProgress.status)) {
            return CourseStatus.Locked
        }
        let lessonStatus = studentProgress.status || CourseStatus.Unknown
        for (const key in studentProgress.courseWeekUnits) {
            if (lessonId in studentProgress.courseWeekUnits[key]) {
                lessonStatus = studentProgress.courseWeekUnits[key][lessonId]
            }
        }

        return lessonStatus
    }

    getChallengeCompletionState(challengeId: string, studentProgress?: CourseProgress): ChallengeStatus {
        if (!studentProgress || !studentProgress.lessonResults) {
            return ChallengeStatus.Unknown
        }

        const status = studentProgress.lessonResults[challengeId]?.result ?? ChallengeStatus.Unknown
        return status
    }

    getStatusForWeekId(weekLabel: string, courseProgress: CourseProgress): CourseStatus {
        //should tell me what's the status for a given week for a specific user's course progression data
        //is the week completed? is it available?
        
        //in case the entire course is at completion already - we don't care about the weeks. there may still be outdated courseProgress data there
        if (courseProgress.status === CourseStatus.Succeeded) {
            return CourseStatus.Completed
        }
        
        const weeksData: any = (courseProgress && courseProgress.courseWeekStatus) ? courseProgress.courseWeekStatus : null
        if (!weeksData) return CourseStatus.Locked
        
        let myWeekStatus = CourseStatus.Unknown
        Object.keys(weeksData).forEach((key) => {
            if (key == weekLabel) {
                myWeekStatus = weeksData[key]
                return
            }
        })

        return myWeekStatus
    }
    
    async getContemplationsStatusFromLessonData(lessonData: Entry<WeekUnitType>, token: string): Promise<Response> {
        //for a specific lessson (week unit), collect all it's contemplation groups
        const results: string[] = []
        lessonData.fields.content.map((content: Entry<any>) => {
            if (content.sys.contentType.sys.id as string === 'weekUnitContemplationGroup') {
                results.push(content.sys.id)
            }
        })
        
        //and get the current status for all of the contemplation groups we just found:
        return fetch('/backend/contemplation/status', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({
                groupIds: results
            })
        })
    }

    getMaximumContemplationsFromLessonData(lessonData: Entry<WeekUnitType>): number {
        let result = 0
        for (const lesson of lessonData.fields.content) {
            if (lesson.sys.contentType.sys.id === 'weekUnitContemplationGroup') {
                result++
            }
        }
        return result
    }
}

export const userProgress = new UserProgress()