import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import classNames from 'classnames'
import { CoursesIcon } from '../icons/CoursesIcon'
import { CommunityIcon } from '../icons/CommunityIcon'
import { UserIcon } from '../icons/UserIcon'
//import { DashboardIcon } from '../icons/DashboardIcon'
import { CourseMenu } from './CourseMenu'
import { useUserNavigation } from '../../contexts/userNavigation'

interface NavigationButtonProps {
    children: React.ReactNode
    className?: string
    href: string
}

const MobileNavigationButton = ({ href, children, className }: NavigationButtonProps) => {
    const { userIsOpeningMenu } = useUserNavigation()

    let classes = classNames('h-full flex items-center content-box', className)
    const router = useRouter()

    const userNavigation = () => {
        userIsOpeningMenu(null)
    }

    const isCurrentPage = href === router.pathname
    if (isCurrentPage) {
        classes += ' border-b-4 border-wing-green'
    }

    classes += ' hover:border-b-4 hover:border-white focus:border-b-4 focus:border-white'

    return (
        <Link href={href} onClickCapture={userNavigation} className={classes}>{children}</Link>
    )
}

export const MobileNavigation = () => {
    const { userIsOpeningMenu } = useUserNavigation()

    const CoursesButton = () => {
        const toggleCourses = () => {
            userIsOpeningMenu('courses')
        }

        return (
            <button type="button" onClick={toggleCourses}><CoursesIcon
                className="fill-current w-8 h-8"/></button>
        )
    }

    const UserMenuButton = () => {
        const toggleUserMenu = () => {
            userIsOpeningMenu('user')
        }

        return (
            <button type="button" onClick={toggleUserMenu}><UserIcon className="stroke-current w-8 h-8"/>
            </button>
        )
    }
    
    return (
        <>
            <CourseMenu className='lg:hidden'/>
            <div
                className="bg-black fixed h-16 bottom-0 w-full z-20 flex flew-row justify-around items-center lg:hidden">
                {/* the dashboard for now remains hidden, since it's just an empty page:
                <MobileNavigationButton href="/"><DashboardIcon
                    className="fill-current w-8 h-8"/></MobileNavigationButton> 
                */}
                <CoursesButton />
                <MobileNavigationButton href="/community"><CommunityIcon
                    className="fill-current w-8 h-8"/></MobileNavigationButton>
                <UserMenuButton />
            </div>
        </>
    )
}
