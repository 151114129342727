import { createContext, ReactNode, useContext, useState } from 'react'

export interface UserNavigationState {
    currentOpenMenu: string | null
    userIsOpeningMenu: (newMenu: string | null) => void
}

const UserNavigationContext = createContext<UserNavigationState | null>(null)

interface ProviderProps {
    children: ReactNode
}

export function UserNavigationProvider({ children }: ProviderProps) {
    const [openMenu, setOpenMenu] = useState<string|null>(null)

    const userIsOpeningMenu = (newMenu: string | null) => {
        //toggle:
        newMenu = newMenu !== null && openMenu === newMenu ? null : newMenu
        setOpenMenu(newMenu)
    }

    return (
        <UserNavigationContext.Provider value={{ currentOpenMenu: openMenu, userIsOpeningMenu: userIsOpeningMenu }}>
            {children}
        </UserNavigationContext.Provider>
    )
}

export function useUserNavigation(): UserNavigationState {
    const context = useContext(UserNavigationContext)
    if (!context) {
        throw new Error('This must be used within the UserNavigation context provider.')
    }

    return context
}
