import * as React from 'react'
import classNames from 'classnames'

type IconProps = {
    className?: string
};

export const CoursesIcon = ({ className, ...props }: IconProps) => {
    const classes = classNames('', className)
    return (
        <svg
            className={classes}
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
            {...props}
        >
            <path
                fillRule="nonzero"
                d="M11.858 23.716C5.32 23.716 0 18.396 0 11.857 0 5.319 5.32-.001 11.858-.001c6.539 0 11.859 5.32 11.859 11.858-.008 6.53-5.328 11.85-11.859 11.859m0-22.001c-5.592 0-10.142 4.55-10.142 10.142a10.06 10.06 0 002.954 7.165 10.054 10.054 0 007.155 2.977h.033C17.45 21.999 22 17.449 22 11.857S17.45 1.715 11.858 1.715m7.718 10.141l-1.29.752-8.999 5.126-1.288.751V5.228l1.287.75 9.001 5.126 1.289.752zM9.717 8.174l-.001 7.365 6.396-3.682-6.395-3.683z"
                transform="matrix(1 0 0 -1 0 23.716)"
            ></path>
        </svg>
    )
}