import { Popover } from '@headlessui/react'
import { signOut, useSession } from 'next-auth/react'
import Link from 'next/link'
import { ReactNode } from 'react'
import { MainButton } from '../buttons/MainButton'
import { LogoWingmen } from '../icons/LogoWingmen'
import { LibraryIcon } from '../icons/LibraryIcon'
import { QuestionIcon } from '../icons/QuestionIcon'
import { DisclaimerIcon } from '../icons/DisclaimerIcon'
import { UserIcon } from '../icons/UserIcon'
import { useUserNavigation } from '../../contexts/userNavigation'

interface UserMenuProps {
    children?: ReactNode
    className?: string
}

export const UserMenu = ({ children, className }: UserMenuProps) => {
    const { data: session } = useSession({ required: true })
    //we can toggle this menu either using the button for the popover, or from the context
    const { currentOpenMenu, userIsOpeningMenu } = useUserNavigation()
    const isOpen = currentOpenMenu === 'user'

    if (!session || !session.user) {
        return <></>
    }

    const user = session.user
    const username = user?.name || 'User Name'
    const handleToggleMenu = () => {
        userIsOpeningMenu('user')
    }

    return (
        <Popover className={className}>
            {children}

            <Popover.Button
                onClickCapture={handleToggleMenu}
                className="invisible lg:visible mr-4">
                <UserIcon className="h-10 w-10 rounded-full inline stroke-current" />
            </Popover.Button>

            { isOpen &&
                <Popover.Panel
                    static
                    className="w-screen absolute top-[0.75rem] lg:top-10 right-[-1rem] lg:right-0 h-[calc(100vh-1rem)] lg:h-auto z-10 bg-black p-2 lg:right-0 lg:w-[32vw] lg:max-w-[32rem] min-w-min lg:origin-top-right rounded-md mt-2">
                    <div className="grid grid-cols-2">
                        <LogoWingmen prefix="mobile-menu" className="h-10 w-10 mb-4 lg:hidden" />
                        <a className="bg-wing-bg col-span-2 m-2 p-2 pl-3 h-20 flex flex-row items-center gap-2 stroke-current">
                            <UserIcon className="h-12 w-12 rounded-full flex-none" />
                            <div className="flex-1 pt-2">
                                <span className="text-sm font-bold block leading-none">{username}</span>
                            </div>
                        </a>

                        <Link 
                            onClickCapture={handleToggleMenu}
                            href="/library" className="bg-wing-bg text-center m-2 px-2 py-4 text-sm font-bold hover:bg-wing-gray-400">
                            <LibraryIcon className="mx-auto w-6 h-6 pb-1 stroke-wing-gray-200 fill-wing-gray-200"/>
                            <span>Library</span>
                        </Link>
                        <Link
                            onClickCapture={handleToggleMenu}
                            href="https://faq.cryptowingmen.com" className="bg-wing-bg text-center m-2 px-2 py-4 text-sm font-bold hover:bg-wing-gray-400">
                            <QuestionIcon className="mx-auto w-6 h-6 pb-1 stroke-wing-gray-200 fill-wing-gray-200"/>
                            <span>Hilfe</span>
                        </Link>
                        <Link
                            onClickCapture={handleToggleMenu}
                            href="/agb" className="bg-wing-bg text-center m-2 px-2 py-4 text-sm font-bold hover:bg-wing-gray-400">
                            <DisclaimerIcon className="mx-auto w-6 h-6 pb-1 stroke-wing-gray-200 fill-wing-gray-200"/>
                            <span>AGB</span>
                        </Link>
                        <Link 
                            onClickCapture={handleToggleMenu}
                            href="/datenschutz" className="bg-wing-bg text-center m-2 px-2 py-4 text-sm font-bold hover:bg-wing-gray-400">
                            <DisclaimerIcon className="mx-auto w-6 h-6 pb-1 stroke-wing-gray-200 fill-wing-gray-200"/>
                            <span>Datenschutz</span>
                        </Link>
                        <Link 
                            onClickCapture={handleToggleMenu}
                            href="/impressum" className="bg-wing-bg text-center m-2 px-2 py-4 text-sm font-bold hover:bg-wing-gray-400">
                            <DisclaimerIcon className="mx-auto w-6 h-6 pb-1 stroke-wing-gray-200 fill-wing-gray-200"/>
                            <span>Impressum</span>
                        </Link>

                        <MainButton className="col-span-2 text-left m-2 py-4 text-center" onClick={() => signOut()}>Abmelden</MainButton>
                    </div>
                </Popover.Panel>
            }
        </Popover>
    )
}
