import { Button, ButtonProps } from '@material-tailwind/react'
import React from 'react'
import classNames from 'classnames'
import { ArrowIcon } from '../icons/ArrowIcon'

interface ButtonProperties extends ButtonProps {
    children: React.ReactNode
    isLeftVariant?: boolean
    className?: string
}

export const MainButton = ({ children, className, isLeftVariant, ...props }: ButtonProperties & any) => {
    const buttonClasses = classNames('whitespace-nowrap px-3 py-2 rounded bg-wing-gray-600 font-bold shadow-none normal-case text-base hover:brightness-125 hover:shadow-none disabled:bg-transparent disabled:border-solid disabled:border-2 disabled:opacity-25', className)
    
    const iconClasses = 'strike-current fill-current w-6 h-6 ' + (isLeftVariant === true ? 'mr-4 float-left rotate-180' : 'float-right ml-4')
    
    return (<Button className={buttonClasses} {...props}>
        <span className="text-sm">{children}</span>
        <ArrowIcon className={iconClasses} />
    </Button>)
}