import * as React from 'react'
import classNames from 'classnames'

type IconProps = {
    className: string
};
 
export const LogoWingmenWhite = ({ className, ...props }: IconProps) => {
    const classes = classNames('', className)
    return (
        <svg className={classes} viewBox="0 0 24 21" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g transform="matrix(1,0,0,-1,-74.784,22.418)">
                <path d="M74.784,22.418L74.784,21.337C74.841,18.152 76.899,15.278 79.904,14.194L80.18,14.094L80.226,13.804C80.652,11.063 82.64,8.764 85.292,7.949L85.577,7.861L85.634,7.568C86.329,3.961 89.48,1.39 93.172,1.484L97.792,1.484L97.792,3.646L93.183,3.646C90.992,3.597 88.988,4.847 88.072,6.844L87.749,7.547L97.792,7.547L97.792,9.71L87.8,9.71C85.608,9.664 83.603,10.912 82.685,12.908L82.362,13.611L97.792,13.611L97.792,15.782L83.026,15.782C80.542,15.701 78.2,17.241 77.255,19.572L76.978,20.255L98.288,20.255L98.288,22.418L74.784,22.418Z" fill="white" fillRule="nonzero"/>
            </g>
        </svg>

    )
}