import * as React from 'react'
import classNames from 'classnames'

type IconProps = {
    className?: string
};

export const LibraryIcon = ({ className, ...props }: IconProps) => {
    const classes = classNames('', className)
    return (
        <svg
            className={classes}
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 25"
            {...props}
        >
            <path
                fillRule="nonzero"
                d="M11.991 0L0 6.103l11.987 6.06L24 6.083 11.991 0zM3.765 6.094l8.222-4.184c1.402.715 5.868 2.978 8.243 4.179-2.378 1.206-6.888 3.491-8.229 4.169-1.368-.695-5.862-2.966-8.236-4.164"
                transform="translate(-1284.06 -500) matrix(1 0 0 -1 1284.06 512.163)"
            ></path>
            <path
                fillRule="nonzero"
                d="M19.527 49.293l-1.879-.953 2.653-1.344c-2.444-1.238-6.847-3.468-8.247-4.184l-8.226 4.187 2.643 1.339-1.875.954c-1.303-.66-4.513-2.291-4.513-2.291l11.978-6.1 11.989 6.095-4.523 2.297"
                transform="translate(-1284.06 -500) matrix(1 0 0 -1 1284 559.096)"
            ></path>
            <path
                fillRule="nonzero"
                d="M19.504 74.359l-1.879-.952 2.686-1.361c-.055-.028-6.914-3.492-8.243-4.167-1.338.676-5.852 2.963-8.229 4.169l2.678 1.356-1.876.954-4.543-2.302 11.984-6.083 11.977 6.082-4.555 2.304"
                transform="translate(-1284.06 -500) matrix(1 0 0 -1 1283.98 590.173)"
            ></path>
        </svg>
    )
}