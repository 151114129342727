import * as React from 'react'
import classNames from 'classnames'

type IconProps = {
    className?: string
};

export const QuestionIcon = ({ className, ...props }: IconProps) => {
    const classes = classNames('', className)
    return (
        <svg
            className={classes}
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 24 24"
            {...props}
        >
            <path
                fillRule="nonzero"
                d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0m0 22.412C6.259 22.412 1.588 17.741 1.588 12 1.588 6.259 6.259 1.588 12 1.588c5.741 0 10.412 4.671 10.412 10.412 0 5.741-4.671 10.412-10.412 10.412"
                transform="translate(-1284.06 -557.002) matrix(1 0 0 -1 1284.06 581.002)"
            ></path>
            <path
                fillRule="nonzero"
                d="M125.092 86.496h-1.588v2.656c0 .342.218.645.543.753.027.009 2.819.996 2.819 2.609a2.57 2.57 0 01-4.99.857l-.264-.749-1.498.527.263.749a4.162 4.162 0 005.3 2.541 4.16 4.16 0 002.776-3.926c0-2.172-2.285-3.429-3.361-3.9v-2.117z"
                transform="translate(-1284.06 -557.002) matrix(1 0 0 -1 1171.67 659.274)"
            ></path>
            <path
                d="M0 0H1.576V1.569H0z"
                transform="translate(-1284.06 -557.002) matrix(1 0 0 -1 1295.16 575.622)"
            ></path>
        </svg>
    )
}