import { ThemeProvider } from '@material-tailwind/react'
import { SessionProvider } from 'next-auth/react'
import { AppProps } from 'next/app'
import type { NextPage } from 'next'
import { ReactElement, ReactNode } from 'react'
import Head from 'next/head'
import { Layout } from '../components/Layout'
import { PaywallWrapper } from '../components/PaywallWrapper'
import '../styles/globals.css'
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { library } = require('@fortawesome/fontawesome-svg-core')

library.add(faEye)
import { wingmenMaterialTheme } from '../styles/material-tailwind-theme'
import { SidebarMenuData, SidebarMenuDataProvider } from '../contexts/sidebarMenuData'
import { UserNavigationProvider } from '../contexts/userNavigation'

export enum RequiredSubscriptionLevel {
    OpenForAll = 0,
    PaidOnly = 1,
}

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
    getLayout?: (page: ReactElement) => ReactNode
    getRequiredSubscriptionLevel?: () => RequiredSubscriptionLevel
}

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout
    sidebarMenuData: SidebarMenuData
}

function MyApp({ Component, pageProps: { session, sidebarMenuData, ...pageProps } }: AppPropsWithLayout) {
    //if defined by the page itself, use it's custom override layout:
    const pageLayout: any = Component.getLayout ? Component.getLayout(<Component {...pageProps}></Component>) :
        //no override, so a default, generic layout:
        <Layout>
            <Component {...pageProps}></Component>
        </Layout>

    return (
        // @ts-ignore
        <ThemeProvider value={wingmenMaterialTheme}>
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
            </Head>
            <SessionProvider session={session}>
                <PaywallWrapper page={Component}>
                    <UserNavigationProvider>
                        <SidebarMenuDataProvider sidebarMenuData={sidebarMenuData}>
                            {pageLayout}
                        </SidebarMenuDataProvider>
                    </UserNavigationProvider>
                </PaywallWrapper>
            </SessionProvider>
        </ThemeProvider>
    )
}

export default MyApp
