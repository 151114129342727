import React from 'react'
import { NextPageWithLayout, RequiredSubscriptionLevel } from '../pages/_app'
import { useSession } from 'next-auth/react'
import AuthLayout from './layout/AuthLayout'
import { PaywallPayButton } from './PaywallPayButton'

const Paywall = () => {
    
    return (
        <AuthLayout 
            title="Oooops, wie es aussieht hast du kein gültiges Abonnement.">
            <span>Klicke auf den Button „Zur Zahlung”, schließe ein Abo ab und erhalte Zugang zur Wingmen Plattform.</span>
            
            <div className="mt-24">
                <PaywallPayButton />
            </div>
        </AuthLayout>
    )
}

export const PaywallWrapper = ({ page, children }: { page: NextPageWithLayout, children: React.ReactNode }) => {
    const { data: session } = useSession()
    
    //we'll be making sure to block any restricted pages, if the current user has no active subscription
    if (session && session.user) { //we only check the already logged in users, this is not site authentication
        const requiredAccess = page.getRequiredSubscriptionLevel ? page.getRequiredSubscriptionLevel() : RequiredSubscriptionLevel.PaidOnly
        if (requiredAccess === RequiredSubscriptionLevel.PaidOnly) {
            if (session.user.hasActiveSubscription !== true) {
                return <Paywall />
            }
        }
    }
    
    //looks like we cleared the paywall, so display the rest of the usual premium content:
    return (
        <>{children}</>
    )
}
